import React from 'react'
import { AuthProvider } from 'authentication-library'

function App() {
  const isLocal = window.location.hostname === 'localhost'

  return isLocal ? <div>Platform home</div> : <AuthProvider app="PLATFORM_HOME" />
}

export default App
